(function() {
    'use strict';
    angular
        .module('autorenlesungApp')
        .factory('FilteredCurrentIssue', FilteredCurrentIssue);

    FilteredCurrentIssue.$inject = ['$resource', 'DateUtils'];

    function FilteredCurrentIssue ($resource, DateUtils) {
        var resourceUrl =  'api/filtered-current-issues';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
        });
    }
})();
