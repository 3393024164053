(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('PlacementDetailController', PlacementDetailController);

    PlacementDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Placement', 'Artist'];

    function PlacementDetailController($scope, $rootScope, $stateParams, previousState, DataUtils, entity, Placement, Artist) {
        var vm = this;

        vm.placement = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.artist = Artist.get({id : $stateParams.id});

        var unsubscribe = $rootScope.$on('autorenlesungApp:placementUpdate', function(event, result) {
            vm.placement = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
