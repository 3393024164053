(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
            .state('placement-detail', {
                parent: 'artist-detail',
                url: '/placement/{placement_id}',
                data: {
                    authorities: ['ROLE_USER'],
                    pageTitle: 'Werbung'
                },
                views: {
                    'content@': {
                        templateUrl: 'app/entities/placement/placement-detail.html',
                        controller: 'PlacementDetailController',
                        controllerAs: 'vm'
                    }
                },
                resolve: {
                    entity: ['$stateParams', 'Placement', function ($stateParams, Placement) {
                        return Placement.get({id: $stateParams.placement_id}).$promise;
                    }],
                    previousState: ["$state", function ($state) {
                        var currentStateData = {
                            name: $state.current.name || 'placement',
                            params: $state.params,
                            url: $state.href($state.current.name, $state.params)
                        };
                        return currentStateData;
                    }]
                }
            })
            .state('placement-detail.edit', {
                parent: 'placement-detail',
                url: '/detail/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/placement/placement-dialog.html',
                        controller: 'PlacementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Placement', function (Placement) {
                                return Placement.get({id: $stateParams.placement_id}).$promise;
                            }]
                        }
                    }).result.then(function() {
                        $state.go('placement-detail', { id: $stateParams.id, placement_id: $stateParams.placement_id}, { reload: 'placement-detail' });
                    }, function() {
                        $state.go('placement-detail',  { id: $stateParams.id, placement_id: $stateParams.placement_id});
                    });
                }]
            })
            .state('artist-detail.new-placement', {
                parent: 'artist-detail',
                url: '/new-placement',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/placement/placement-dialog.html',
                        controller: 'PlacementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: function () {
                                return {
                                    title: null,
                                    description: null,
                                    url: null,
                                    image: null,
                                    imageContentType: null,
                                    id: null
                                };
                            }
                        }
                    }).result.then(function () {
                        $state.go('artist-detail', null, {reload: 'artist-detail'});
                    }, function () {
                        $state.go('artist-detail');
                    });
                }]
            })
            .state('artist-detail.edit-placement', {
                parent: 'artist-detail',
                url: '/placement/{placement_id}/edit',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/placement/placement-dialog.html',
                        controller: 'PlacementDialogController',
                        controllerAs: 'vm',
                        backdrop: 'static',
                        size: 'lg',
                        resolve: {
                            entity: ['Placement', function (Placement) {
                                return Placement.get({id: $stateParams.placement_id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('artist-detail', null, {reload: 'artist-detail'});
                    }, function () {
                        $state.go('artist-detail');
                    });
                }]
            })
            .state('artist-detail.delete-placement', {
                parent: 'artist-detail',
                url: '/placement/{placement_id}/delete',
                data: {
                    authorities: ['ROLE_USER']
                },
                onEnter: ['$stateParams', '$state', '$uibModal', function ($stateParams, $state, $uibModal) {
                    $uibModal.open({
                        templateUrl: 'app/entities/placement/placement-delete-dialog.html',
                        controller: 'PlacementDeleteController',
                        controllerAs: 'vm',
                        size: 'md',
                        resolve: {
                            entity: ['Placement', function (Placement) {
                                return Placement.get({id: $stateParams.placement_id}).$promise;
                            }]
                        }
                    }).result.then(function () {
                        $state.go('artist-detail', null, {reload: 'artist-detail'});
                    }, function () {
                        $state.go('artist-detail');
                    });
                }]
            });
    }

})();
