(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('AccommodationSuggestionController', AccommodationSuggestionController);

    AccommodationSuggestionController.$inject = ['$scope', '$state', 'AccommodationSuggestion'];

    function AccommodationSuggestionController ($scope, $state, AccommodationSuggestion) {
        var vm = this;

        vm.accommodationSuggestions = [];

        loadAll();

        function loadAll() {
            AccommodationSuggestion.query(function(result) {
                vm.accommodationSuggestions = result;
            });
        }
    }
})();
