(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('AuthorController', AuthorController);

    AuthorController.$inject = ['$scope', 'Principal', 'LoginService', '$state', 'Author', 'authors', 'Hit', 'hits'];

    function AuthorController ($scope, Principal, LoginService, $state, Author, authors, Hits, hits) {
        var vm = this;
        vm.authors = authors;
        vm.hits = hits;

        vm.urlify = urlify;

        function urlify (name) {
            return name.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').toLowerCase();
        }

        $scope.$watch('vm.age', function (nVal, oVal)
        {
            // if searchStr is still the same..
            // go ahead and retrieve the data
            if (nVal != oVal)
            {
                if(nVal==null) {
                    vm.authors = Author.query();
                } else {
                    vm.authors = Author.query({age: nVal});
                }
            }

        });

    }
})();
