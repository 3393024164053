(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('HitDetailController', HitDetailController);

    HitDetailController.$inject = ['$scope', '$state', '$rootScope', '$stateParams', 'previousState', 'DataUtils', 'entity', 'Artist', 'Event', 'Placement', 'EventsPerArtist', 'PlacementsPerArtist'];

    function HitDetailController($scope, $state, $rootScope, $stateParams, previousState, DataUtils, entity, Artist, Event, Placement, EventsPerArtist, PlacementsPerArtist) {
        var vm = this;

        vm.artist = entity;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        var actualPageTitle = vm.artist.title.replace(/[^\w\s]/gi, '').replace(/\s+/g, '-').toLowerCase();
        if(actualPageTitle !=$stateParams.pageTitle) {
            $state.go("hit-detail", {
                id: vm.artist.id,
                pageTitle: actualPageTitle
            });
        }

        vm.artist.events = EventsPerArtist.get({id : vm.artist.id, active: true});
        vm.artist.placements = PlacementsPerArtist.get({id : vm.artist.id});

        var unsubscribe = $rootScope.$on('autorenlesungApp:artistUpdate', function(event, result) {
            vm.artist = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
