(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('NewsletterDialogController', NewsletterDialogController);

    NewsletterDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'Newsletter'];

    function NewsletterDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, Newsletter) {
        var vm = this;

        vm.newsletter = {};
        vm.clear = clear;
        vm.save = save;
        vm.error = null;
        vm.tos = false;

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
            vm.error = null;
        }

        function save () {
            vm.isSaving = true;
            Newsletter.save(vm.newsletter, onSaveSuccess, onSaveError);
        }

        function onSaveSuccess (result) {
            $uibModalInstance.close(result);
            vm.isSaving = false;
            vm.error = null;
        }

        function onSaveError () {
            vm.isSaving = false;
            vm.error = true;
        }
    }
})();
