(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('user-reservation', {
            parent: 'app',
            url: '/event/{id}/reservation',
            data: {
                authorities: [],
                pageTitle: 'Reservierung'
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-reservation/user-reservation.html',
                    controller: 'UserReservationController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                event: ['$stateParams', 'Event', function($stateParams, Event) {
                    return Event.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('user-reservation-success', {
            parent: 'app',
            url: '/reservation-success',
            data: {
                authorities: [],
                pageTitle: 'Reservierung erfolgreich'
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-reservation/user-reservation-success.html'
                }
            }
        });
    }

})();
