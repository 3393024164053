(function() {
    'use strict';

    angular
        .module('autorenlesungApp', [
            'ngStorage',
            'ngResource',
            'ngCookies',
            'ngAria',
            'ngCacheBuster',
            'ngFileUpload',
            'ui.bootstrap',
            'ui.bootstrap.datetimepicker',
            'ui.router',
            'infinite-scroll',
            // jhipster-needle-angularjs-add-module JHipster will add new module here
            'angular-loading-bar',
            'ui.tinymce',
            'angular-google-analytics'
        ])
        .filter('checkmark', function() {
        return function getCheckmark(input) {
            return input ? '\u2713' : '\u2718';
        }})
        .filter('type', function() {
            return function getTypeString(input) {
                return input=='AUTHOR' ? 'Autor' : 'Hit';
            }})
        .filter('gender', function() {
            return function getTypeString(input) {
                return input=='MALE' ? 'Männlich' : 'Weiblich';
            }})
        .filter('unsafe',['$sce',  function($sce) {
            return $sce.trustAsHtml;
        }])
        .config(['AnalyticsProvider', function (AnalyticsProvider) {
            // Add configuration code as desired
            AnalyticsProvider.setAccount('UA-8591059-1');
        }])
        .run(run);

    run.$inject = ['stateHandler', '$rootScope', '$document', 'Analytics'];

    function run(stateHandler, $rootScope, $document, Analytics) {
        stateHandler.initialize();
        $rootScope.$on('$stateChangeSuccess', function() {
            $document[0].body.scrollTop = $document[0].documentElement.scrollTop = 0;
        });
    }
})();
