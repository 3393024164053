(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('UserPriceInquiryController', UserPriceInquiryController);

    UserPriceInquiryController.$inject = ['$scope', '$state', 'PriceInquiry', 'ParseLinks', 'AlertService', 'Artist', '$stateParams', 'event'];

    function UserPriceInquiryController ($scope, $state, PriceInquiry, ParseLinks, AlertService, Artist, $stateParams, event) {
        var vm = this;

        vm.priceInquiry = {};
        vm.priceInquiry.eventId = $stateParams.id;
        vm.event = event;
        vm.artist = Artist.get({id: vm.event.artistId});
        vm.save = save;
        vm.error = null;
        vm.tos = false;

        function save() {
            vm.isSaving = true;
            PriceInquiry.save(vm.priceInquiry, onSaveSuccess, onSaveError)
        }

        function onSaveSuccess (result) {
            $scope.$emit('autorenlesungApp:priceInquiryUpdate', result);
            vm.isSaving = false;
            $state.go("user-price-inquiry-success");
            vm.error = null;
        }

        function onSaveError () {
            vm.isSaving = false;
            vm.error = true;
        }
    }
})();
