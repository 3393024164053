(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider.state('hits', {
            parent: 'app',
            url: '/hits',
            data: {
                authorities: [],
                pageTitle: "Hits"
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-hit/hits.html',
                    controller: 'HitController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                hits: ['Hit', function(Hit) {
                    return Hit.query();
                }]
            }
        })
        .state('hit-detail-id-only', {
            parent: 'hits',
            url: '/{id}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-hit/hit-detail.html',
                    controller: 'HitDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Artist', function($stateParams, Artist) {
                    return Artist.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'artist',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('hit-detail', {
            parent: 'hits',
            url: '/{id}/{pageTitle}',
            data: {
                authorities: []
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-hit/hit-detail.html',
                    controller: 'HitDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Artist', function($stateParams, Artist) {
                    return Artist.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'artist',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        });
    }
})();
