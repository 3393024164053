(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('ReservationController', ReservationController);

    ReservationController.$inject = ['$http','$scope', '$state', 'Reservation', 'ParseLinks', 'AlertService'];

    function ReservationController ($http, $scope, $state, Reservation, ParseLinks, AlertService) {
        var vm = this;

        vm.reservations = [];
        vm.loadPage = loadPage;
        vm.page = 0;
        vm.links = {
            last: 0
        };
        vm.predicate = 'id';
        vm.reset = reset;
        vm.reverse = false;

        vm.exportReservations = exportReservations;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        vm.datePickerOpenStatus.from = false;
        vm.datePickerOpenStatus.to = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }

        loadAll();

        function loadAll () {
            Reservation.query({
                page: vm.page,
                size: 20,
                sort: sort()
            }, onSuccess, onError);
            function sort() {
                var result = [vm.predicate + ',' + (vm.reverse ? 'asc' : 'desc')];
                if (vm.predicate !== 'id') {
                    result.push('id');
                }
                return result;
            }

            function onSuccess(data, headers) {
                vm.links = ParseLinks.parse(headers('link'));
                vm.totalItems = headers('X-Total-Count');
                for (var i = 0; i < data.length; i++) {
                    vm.reservations.push(data[i]);
                }
            }

            function onError(error) {
                AlertService.error(error.data.message);
            }
        }

        function reset () {
            vm.page = 0;
            vm.reservations = [];
            loadAll();
        }

        function loadPage(page) {
            vm.page = page;
            loadAll();
        }

        function exportReservations () {
            vm.isExporting = true;
            $http.post('api/reservationExport', {from: vm.from, to: vm.to}).then(function (response) {
                var blob = new Blob([response.data], {
                    type: "text/csv;charset=UTF-8"
                });

                var filename = "reservierungen.csv";
                saveAs(blob, filename);
                vm.isExporting = false;
            }, function (error) {
                vm.isExporting = false;
                AlertService.error(error);
            })
        }
    }
})();
