(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('event-detail', {
            parent: 'artist-detail',
            url: '/event/{event_id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Veranstaltung'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/event/event-detail.html',
                    controller: 'EventDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Event', function($stateParams, Event) {
                    return Event.get({id : $stateParams.event_id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'event',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('event-content', {
            parent: 'event-detail',
            url: '/content',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Veranstaltung'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/event/event-content.html',
                    controller: 'EventContentController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'Event', function($stateParams, Event) {
                    return Event.get({id : $stateParams.event_id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'event',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('event-detail.edit', {
            parent: 'artist-detail',
            url: '/event/{event_id}/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-dialog.html',
                    controller: 'EventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.event_id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('event-detail', { id: $stateParams.id, event_id: $stateParams.event_id}, { reload: 'event-detail' });
                }, function() {
                    $state.go('event-detail',  { id: $stateParams.id, event_id: $stateParams.event_id});
                });
            }]
        })
        .state('artist-detail.new-event', {
            parent: 'artist-detail',
            url: '/new-event',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-dialog.html',
                    controller: 'EventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                title: null,
                                content: null,
                                artistContact: null,
                                accommodationRequest: null,
                                position: null,
                                active: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('artist-detail', null, { reload: 'artist-detail' });
                }, function() {
                    $state.go('artist-detail');
                });
            }]
        })
        .state('artist-detail.edit-event', {
            parent: 'artist-detail',
            url: '/event/{event_id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-dialog.html',
                    controller: 'EventDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.event_id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('artist-detail', null, { reload: 'artist-detail' });
                }, function() {
                    $state.go('artist-detail');
                });
            }]
        })
        .state('artist-detail.delete-event', {
            parent: 'artist-detail',
            url: '/event/{event_id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/event/event-delete-dialog.html',
                    controller: 'EventDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['Event', function(Event) {
                            return Event.get({id : $stateParams.event_id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('artist-detail', null, { reload: 'artist-detail' });
                }, function() {
                    $state.go('artist-detail');
                });
            }]
        })
        ;
    }

})();
