(function() {
    'use strict';
    angular
        .module('autorenlesungApp')
        .factory('Hit', Hit);

    Hit.$inject = ['$resource'];

    function Hit ($resource) {
        var resourceUrl =  'api/hits';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
