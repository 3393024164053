(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('UserReservationController', UserReservationController);

    UserReservationController.$inject = ['$scope', '$state', 'Reservation', 'ParseLinks', 'AlertService', 'Artist', '$stateParams', 'event', 'AccommodationSuggestion'];

    function UserReservationController ($scope, $state, Reservation, ParseLinks, AlertService, Artist, $stateParams, event, AccommodationSuggestion) {
        var vm = this;

        vm.reservation = {};
        vm.accommodationSuggestion = {};
        vm.reservation.eventId = $stateParams.id;
        vm.event = event;
        vm.artist = Artist.get({id: vm.event.artistId});
        vm.save = save;
        vm.error = null;
        vm.tos = false;

        vm.datePickerOpenStatus = {};
        vm.openCalendar = openCalendar;

        var lessonStartStartTime = new Date();
        lessonStartStartTime.setHours(7);
        lessonStartStartTime.setMinutes(20);
        lessonStartStartTime.setSeconds(0);
        lessonStartStartTime.setMilliseconds(0);
        var lessonStartEndTime = new Date();
        lessonStartEndTime.setHours(8);
        lessonStartEndTime.setMinutes(30);
        lessonStartEndTime.setSeconds(0);
        lessonStartEndTime.setMilliseconds(0);
        var breakFromStartTime = new Date();
        breakFromStartTime.setHours(9);
        breakFromStartTime.setMinutes(0);
        breakFromStartTime.setSeconds(0);
        breakFromStartTime.setMilliseconds(0);
        var breakFromEndTime = new Date();
        breakFromEndTime.setHours(10);
        breakFromEndTime.setMinutes(50);
        breakFromEndTime.setSeconds(0);
        breakFromEndTime.setMilliseconds(0);
        var breakToStartTime = new Date();
        breakToStartTime.setHours(9);
        breakToStartTime.setMinutes(10);
        breakToStartTime.setSeconds(0);
        breakToStartTime.setMilliseconds(0);
        var breakToEndTime = new Date();
        breakToEndTime.setHours(11);
        breakToEndTime.setMinutes(15);
        breakToEndTime.setSeconds(0);
        breakToEndTime.setMilliseconds(0);

        vm.timesLessonStart = timeRange(lessonStartStartTime, lessonStartEndTime);
        vm.timesBreakFrom = timeRange(breakFromStartTime, breakFromEndTime);
        vm.timesBreakTo =timeRange(breakToStartTime, breakToEndTime);

        function timeRange(from, to) {
            var range = []
            range.push(from)
            var current = from
            while(current.getTime() != to.getTime()) {
                current = incrementedDate(current)
                range.push(current)
            }
            return range
        }

        function incrementedDate(old) {
            var d = new Date(old);
            d.setMinutes(d.getMinutes() + 5);
            return d;
        }

        function save() {
            vm.isSaving = true;

            //save suggestion first if given
            if(acommodationSuggestionExists()) {
                AccommodationSuggestion.save(vm.accommodationSuggestion, onAccSaveSuccess, onAccSaveError)
            }
            else {
                Reservation.save(vm.reservation, onSaveSuccess, onSaveError);
            }
        }

        function acommodationSuggestionExists() {
            return (vm.accommodationSuggestion.name!=null && vm.accommodationSuggestion.name.length>0) ||
                   (vm.accommodationSuggestion.email!=null && vm.accommodationSuggestion.email.length>0) ||
                   (vm.accommodationSuggestion.phone!=null && vm.accommodationSuggestion.phone.length>0) ||
                   (vm.accommodationSuggestion.comment!=null && vm.accommodationSuggestion.comment.length>0)
        }

        function onSaveSuccess (result) {
            $scope.$emit('autorenlesungApp:reservationUpdate', result);
            vm.isSaving = false;
            vm.error = null;
            $state.go("user-reservation-success");
        }

        function onSaveError () {
            vm.isSaving = false;
            vm.error = true;
        }

        function onAccSaveSuccess (result) {
            console.log(result);
            vm.reservation.accommodationSuggestionsId = result.id;
            Reservation.save(vm.reservation, onSaveSuccess, onSaveError);
            vm.error = null;
        }

        function onAccSaveError () {
            vm.isSaving = false;
            vm.error = true;
        }

        vm.datePickerOpenStatus.desiredDate = false;
        vm.datePickerOpenStatus.alternateDate = false;
        vm.datePickerOpenStatus.lessonStart = false;
        vm.datePickerOpenStatus.breakFrom = false;
        vm.datePickerOpenStatus.breakTo = false;
        vm.datePickerOpenStatus.submissionDate = false;

        function openCalendar (date) {
            vm.datePickerOpenStatus[date] = true;
        }
    }
})();
