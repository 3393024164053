(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('EventDialogController', EventDialogController);

    EventDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Event', 'Reservation', 'PriceInquiry', 'CurrentIssue', 'Artist'];

    function EventDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Event, Reservation, PriceInquiry, CurrentIssue, Artist) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.event.artistId = $stateParams.id;

        if(vm.event.artistContact == null) vm.event.artistContact = false;
        if(vm.event.accommodationRequest == null) vm.event.accommodationRequest = false;
        if(vm.event.active == null) vm.event.active = true;
        if(vm.event.priceInformationText == null) vm.event.priceInformationText = ' ';


        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.event.id !== null) {
                Event.update(vm.event, onSaveSuccess, onSaveError);
            } else {
                Event.save(vm.event, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autorenlesungApp:eventUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.tinymceOptions = {
            onChange: function(e) {
                // put logic here for keypress and cut/paste changes
            },
            inline: false,
            plugins : 'advlist autolink link image lists charmap print preview table contextmenu',
            skin: 'lightgray',
            theme : 'modern',
            height: 150,
            image_advtab: true,
            language : 'de'
        };

    }
})();
