(function() {
    'use strict';
    angular
        .module('autorenlesungApp')
        .factory('Reservation', Reservation);

    Reservation.$inject = ['$resource', 'DateUtils'];

    function Reservation ($resource, DateUtils) {
        var resourceUrl =  'api/reservations/:id';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true},
            'get': {
                method: 'GET',
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                        data.desiredDate = DateUtils.convertDateTimeFromServer(data.desiredDate);
                        data.alternateDate = DateUtils.convertDateTimeFromServer(data.alternateDate);
                        data.lessonStart = DateUtils.convertDateTimeFromServer(data.lessonStart);
                        data.breakFrom = DateUtils.convertDateTimeFromServer(data.breakFrom);
                        data.breakTo = DateUtils.convertDateTimeFromServer(data.breakTo);
                        data.submissionDate = DateUtils.convertDateTimeFromServer(data.submissionDate);
                    }
                    return data;
                }
            },
            'update': { method:'PUT' }
        });
    }
})();
