(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('ArtistDialogController', ArtistDialogController);

    ArtistDialogController.$inject = ['$timeout', '$scope', '$stateParams', '$uibModalInstance', 'DataUtils', 'entity', 'Artist', 'Event', 'Placement'];

    function ArtistDialogController ($timeout, $scope, $stateParams, $uibModalInstance, DataUtils, entity, Artist, Event, Placement) {
        var vm = this;

        vm.artist = entity;
        if(vm.artist.active == null) vm.artist.active = true;
        if(vm.artist.type == null) vm.artist.type = 'AUTHOR';

        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.events = Event.query();
        vm.placements = Placement.query();

        $timeout(function (){
            angular.element('.form-group:eq(1)>input').focus();
        });

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.artist.id !== null) {
                Artist.update(vm.artist, onSaveSuccess, onSaveError);
            } else {
                Artist.save(vm.artist, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autorenlesungApp:artistUpdate', result);
            $uibModalInstance.close(result);
            vm.isSaving = false;
        }

        function onSaveError () {
            vm.isSaving = false;
        }


        vm.setImage = function ($file, artist) {
            if ($file && $file.$error === 'pattern') {
                return;
            }
            if ($file) {
                DataUtils.toBase64($file, function(base64Data) {
                    $scope.$apply(function() {
                        artist.image = base64Data;
                        artist.imageContentType = $file.type;
                    });
                });
            }
        };

    }
})();
