(function() {
    'use strict';
    angular
        .module('autorenlesungApp')
        .factory('Author', Author);

    Author.$inject = ['$resource'];

    function Author ($resource) {
        var resourceUrl =  'api/authors';

        return $resource(resourceUrl, {}, {
            'query': { method: 'GET', isArray: true}
        });
    }
})();
