(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('ArtistContentController', ArtistContentController);

    ArtistContentController.$inject = ['$scope', '$rootScope', '$state',  '$stateParams', 'previousState', 'DataUtils', 'entity', 'Artist', 'Event', 'Placement', 'EventsPerArtist'];

    function ArtistContentController($scope, $rootScope, $state, $stateParams, previousState, DataUtils, entity, Artist, Event, Placement, EventsPerArtist) {
        var vm = this;

        vm.save = save;
        vm.artist = entity;
        vm.previousState = previousState.name;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.artist.id !== null) {
                Artist.update(vm.artist, onSaveSuccess, onSaveError);
            } else {
                Artist.save(vm.artist, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autorenlesungApp:artistUpdate', result);
            vm.isSaving = false;
            $state.go("artist-detail", { "id": vm.artist.id});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        $scope.tinymceOptions = {
            onChange: function(e) {
                // put logic here for keypress and cut/paste changes
            },
            inline: false,
            plugins : 'advlist autolink link image lists charmap print preview table contextmenu',
            skin: 'lightgray',
            theme : 'modern',
            height: 500,
            image_advtab: true,
            language : 'de'
        };

        //var unsubscribe = $rootScope.$on('autorenlesungApp:artistUpdate', function(event, result) {
        //    vm.artist = result;
        //});
        //$scope.$on('$destroy', unsubscribe);
    }
})();
