(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('user-price-inquiry', {
            parent: 'app',
            url: '/event/{id}/price-inquiry',
            data: {
                authorities: [],
                pageTitle: 'Preisanfrage'
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-price-inquiry/user-price-inquiry.html',
                    controller: 'UserPriceInquiryController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                event: ['$stateParams', 'Event', function($stateParams, Event) {
                    return Event.get({id : $stateParams.id}).$promise;
                }]
            }
        })
        .state('user-price-inquiry-success', {
            parent: 'app',
            url: '/price-inquiry-success',
            data: {
                authorities: [],
                pageTitle: 'Preisanfrage erfolgreich'
            },
            views: {
                'content@': {
                    templateUrl: 'app/user-price-inquiry/user-price-inquiry-success.html'
                }
            }
        })
    }

})();
