(function() {
    'use strict';
    angular
        .module('autorenlesungApp')
        .factory('PlacementsPerArtist', PlacementsPerArtist);

    PlacementsPerArtist.$inject = ['$resource'];

    function PlacementsPerArtist ($resource) {
        var resourceUrl =  'api/artists/:id/placements';

        return $resource(resourceUrl, {}, {
            'get': {
                method: 'GET',
                isArray: true,
                transformResponse: function (data) {
                    if (data) {
                        data = angular.fromJson(data);
                    }
                    return data;
                }
            }
        });
    }
})();
