(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('PriceInquiryDetailController', PriceInquiryDetailController);

    PriceInquiryDetailController.$inject = ['$scope', '$rootScope', '$stateParams', 'previousState', 'entity', 'PriceInquiry', 'Event'];

    function PriceInquiryDetailController($scope, $rootScope, $stateParams, previousState, entity, PriceInquiry, Event) {
        var vm = this;

        vm.priceInquiry = entity;
        vm.previousState = previousState.name;

        var unsubscribe = $rootScope.$on('autorenlesungApp:priceInquiryUpdate', function(event, result) {
            vm.priceInquiry = result;
        });
        $scope.$on('$destroy', unsubscribe);
    }
})();
