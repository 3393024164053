(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .config(stateConfig);

    stateConfig.$inject = ['$stateProvider'];

    function stateConfig($stateProvider) {
        $stateProvider
        .state('price-inquiry', {
            parent: 'entity',
            url: '/price-inquiry',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'Preisanfragen'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/price-inquiry/price-inquiries.html',
                    controller: 'PriceInquiryController',
                    controllerAs: 'vm'
                }
            },
            params: {
                sort: {
                    value: 'submissionDate,desc',
                    squash: true
                }
            },
            resolve: {
            }
        })
        .state('price-inquiry-detail', {
            parent: 'entity',
            url: '/price-inquiry/{id}',
            data: {
                authorities: ['ROLE_USER'],
                pageTitle: 'PriceInquiry'
            },
            views: {
                'content@': {
                    templateUrl: 'app/entities/price-inquiry/price-inquiry-detail.html',
                    controller: 'PriceInquiryDetailController',
                    controllerAs: 'vm'
                }
            },
            resolve: {
                entity: ['$stateParams', 'PriceInquiry', function($stateParams, PriceInquiry) {
                    return PriceInquiry.get({id : $stateParams.id}).$promise;
                }],
                previousState: ["$state", function ($state) {
                    var currentStateData = {
                        name: $state.current.name || 'price-inquiry',
                        params: $state.params,
                        url: $state.href($state.current.name, $state.params)
                    };
                    return currentStateData;
                }]
            }
        })
        .state('price-inquiry-detail.edit', {
            parent: 'price-inquiry-detail',
            url: '/detail/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/price-inquiry/price-inquiry-dialog.html',
                    controller: 'PriceInquiryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PriceInquiry', function(PriceInquiry) {
                            return PriceInquiry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('^', {}, { reload: false });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('price-inquiry.new', {
            parent: 'price-inquiry',
            url: '/new',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/price-inquiry/price-inquiry-dialog.html',
                    controller: 'PriceInquiryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: function () {
                            return {
                                organizationName: null,
                                organizerName: null,
                                organizerEmail: null,
                                comment: null,
                                id: null
                            };
                        }
                    }
                }).result.then(function() {
                    $state.go('price-inquiry', null, { reload: 'price-inquiry' });
                }, function() {
                    $state.go('price-inquiry');
                });
            }]
        })
        .state('price-inquiry.edit', {
            parent: 'price-inquiry',
            url: '/{id}/edit',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/price-inquiry/price-inquiry-dialog.html',
                    controller: 'PriceInquiryDialogController',
                    controllerAs: 'vm',
                    backdrop: 'static',
                    size: 'lg',
                    resolve: {
                        entity: ['PriceInquiry', function(PriceInquiry) {
                            return PriceInquiry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('price-inquiry', null, { reload: 'price-inquiry' });
                }, function() {
                    $state.go('^');
                });
            }]
        })
        .state('price-inquiry.delete', {
            parent: 'price-inquiry',
            url: '/{id}/delete',
            data: {
                authorities: ['ROLE_USER']
            },
            onEnter: ['$stateParams', '$state', '$uibModal', function($stateParams, $state, $uibModal) {
                $uibModal.open({
                    templateUrl: 'app/entities/price-inquiry/price-inquiry-delete-dialog.html',
                    controller: 'PriceInquiryDeleteController',
                    controllerAs: 'vm',
                    size: 'md',
                    resolve: {
                        entity: ['PriceInquiry', function(PriceInquiry) {
                            return PriceInquiry.get({id : $stateParams.id}).$promise;
                        }]
                    }
                }).result.then(function() {
                    $state.go('price-inquiry', null, { reload: 'price-inquiry' });
                }, function() {
                    $state.go('^');
                });
            }]
        });
    }

})();
