(function() {
    'use strict';

    angular
        .module('autorenlesungApp')
        .controller('EventContentController', EventContentController);

    EventContentController.$inject = ['$scope', '$rootScope', '$state',  '$stateParams', 'previousState', 'DataUtils', 'entity', 'Artist', 'Event', 'Placement'];

    function EventContentController($scope, $rootScope, $state, $stateParams, previousState, DataUtils, entity, Artist, Event, Placement) {
        var vm = this;

        vm.event = entity;
        vm.clear = clear;
        vm.byteSize = DataUtils.byteSize;
        vm.openFile = DataUtils.openFile;
        vm.save = save;
        vm.event.artistId = $stateParams.id;
        vm.artist = Artist.get({id : $stateParams.id});

        function clear () {
            $uibModalInstance.dismiss('cancel');
        }

        function save () {
            vm.isSaving = true;
            if (vm.event.id !== null) {
                Event.update(vm.event, onSaveSuccess, onSaveError);
            } else {
                Event.save(vm.event, onSaveSuccess, onSaveError);
            }
        }

        function onSaveSuccess (result) {
            $scope.$emit('autorenlesungApp:eventUpdate', result);
            vm.isSaving = false;
            $state.go("event-detail", { "id": vm.event.artistId, "event_id": vm.event.id});
        }

        function onSaveError () {
            vm.isSaving = false;
        }

        //var unsubscribe = $rootScope.$on('autorenlesungApp:artistUpdate', function(event, result) {
        //    vm.artist = result;
        //});
        //$scope.$on('$destroy', unsubscribe);

        $scope.tinymceOptions = {
            onChange: function(e) {
                // put logic here for keypress and cut/paste changes
            },
            inline: false,
            plugins : 'advlist autolink link image lists charmap print preview table contextmenu',
            skin: 'lightgray',
            theme : 'modern',
            height: 500,
            image_advtab: true,
            language : 'de'
        };
    }
})();
